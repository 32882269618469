import { Component, OnInit } from '@angular/core';
import { DeviceSizeFinderService } from '../device-size-finder.service';

@Component({
  selector: 'app-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.css']
})
export class TitleBarComponent implements OnInit {

  constructor(public deviceSize: DeviceSizeFinderService) {
   }

  ngOnInit(): void { }

  testclick(){
    console.log("clicked the button");
  }
}
