import { Component, OnInit } from '@angular/core';
import { DeviceSizeFinderService } from '../device-size-finder.service';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.css']
})
export class ProjectCardComponent implements OnInit {

  public project = {
    title: "Brass Necessity",
    subtitle: "Can you escape the gauntlet?",
    titleIcon: "icons8-colosseum-96",
    designDocs : {
      names: [
        "Design Document",
        "Design Session 1 Notes",
        "Design Session 2 Notes",
        "Final Specs"
      ],
      links: [
        "https://docs.google.com/document/d/1aMIWAsf2TzN7g15oAo_UVePQwB65UIsHKrItZ5QxHDs/edit?usp=sharing",
        "https://docs.google.com/document/d/1GRCAGIZk29_WONTTz-coL6-zb4wbcca2ynxxNz9fR3k/edit?usp=sharing",
        "https://docs.google.com/document/d/1a-ChpKpX2ZI6q9Asfh86tvAm4xGgPambBCMMt8Q-1fc/edit?usp=sharing",
        "https://docs.google.com/document/d/1YZd6amjLXRrgQ5JY-Ef-L6w96NwCtW0rmxCChfLLInc/edit?usp=sharing"
      ]
    },
    projectLinks : {
      names: [
        "GitHub",
        "Trello Sprints",
        "Trello Roadmap",
        "Tool Guide/Overview",
        "Technical Planning/Drawing"
      ],
      links: [
        "https://github.com/zkturman/BrassNecessity",
        "https://trello.com/invite/b/qgwAWODh/ATTIc4ecd86b212a95f73088abce30fc8c933D4C7A38/task-tracker",
        "https://trello.com/invite/b/PtrbcuzO/ATTI21560454f3334e4a1a2f8fb39c215a5a71761F5E/roadmap",
        "https://docs.google.com/document/d/1v56n4lBaBPLTv1bgzlgBvs11p_DapsRPdN9Ydl5ZqGo/edit#heading=h.xxvluxra2bik",
        "https://drive.google.com/file/d/11_RIcrzbe_msdjCzKtqnk6sTxeXRcjcC/view?usp=sharing"
      ]
    },
    archivedLinks : {
      names: [
        "Design Session 1 Notes (before rework)",
        "Design Session 2 Notes (before rework)",
        "Design Session 3 Notes (before rework)"
      ],
      links: [
        "https://docs.google.com/document/d/1zHtwZRDF7KvdZhQ8s_jkdjpiUp858V82_Y__p8mi5iE/edit?usp=sharing",
        "https://docs.google.com/document/d/1gTR58as22zNqh4mzOuBAtwoOl9jd45shIDp11Gk6Cag/edit?usp=sharing",
        "https://docs.google.com/document/d/1o0j3hzmpvkqDorLnDHZ36AKUMzpyYo0pS-wNtjq2yWs/edit?usp=sharing"
      ]
    } 
  };
  constructor(public deviceSize: DeviceSizeFinderService) { }

  ngOnInit(): void {
  }

}
