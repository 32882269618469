<div [ngClass]="{'is-phone-portrait' : this.deviceSize.getIsPhonePortrait(), 'is-phone-landscape' : this.deviceSize.getIsPhoneLandscape(),
'is-tablet-portrait' : this.deviceSize.getIsTabletPortrait(), 'is-tablet-landscape' : this.deviceSize.getIsTabletLandscape()}">
    <app-welcome-banner></app-welcome-banner>
    <div class="content-container">
        <div class="flex-container flex-column">
            <!-- <img class="svg-image" src="../../assets/Innovation-amico.svg"> -->
            <app-splash-image class="svg-image"></app-splash-image>
            <a class="svg-citation" href="https://storyset.com/technology">Technology illustrations by Storyset.</a>
        </div>
        <div class="flex-container flex-item">
            <button mat-raised-button color="accent" [routerLink]="'/projects'">Projects</button>
            <button mat-raised-button color="accent" [routerLink]="'/about'">About</button>
            <button mat-raised-button color="accent" [routerLink]="'/events'">Events</button>
            <button mat-raised-button color="accent" [routerLink]="'/join'">Join</button>
        </div>
    </div>
</div>

