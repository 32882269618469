<div class="flex-container">
    <div class="flex-item">
        <h3>
            Current Projects
        </h3>
        <app-project-card></app-project-card>
        <h3>
            Previous Projects
        </h3>
        <span class="twitch-links">
            <iframe frameborder="0" src="https://itch.io/embed/1760277?border_width=0&amp;bg_color=c39af3&amp;link_color=1b784a&amp;border_color=c39af3" width="206" height="165"><a href="https://www500.itch.io/under-rail">Under Rail by www500, timph</a></iframe>
            <iframe frameborder="0" src="https://itch.io/embed/1687111?border_width=0&amp;bg_color=c39af3&amp;link_color=1b784a&amp;border_color=c39af3" width="206" height="165"><a href="https://weirdos-klamber.itch.io/fungiosis">Fungiosis by Weirdos_Klamber, www500</a></iframe>    
        </span>
    </div>
</div>
