import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TitleBarComponent } from './title-bar/title-bar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { SiteFooterComponent } from './site-footer/site-footer.component';
import { WelcomeBannerComponent } from './welcome-banner/welcome-banner.component';
import { HomeViewComponent } from './home-view/home-view.component';
import { ProjectCardComponent } from './project-card/project-card.component';
import { MatCardModule } from '@angular/material/card';
import { ProjectOverviewComponent } from './project-overview/project-overview.component';
import { AppRoutingModule } from './app-routing.module';
import { AboutViewComponent } from './about-view/about-view.component';
import { JoinViewComponent } from './join-view/join-view.component';
import { ProjectsViewComponent } from './projects-view/projects-view.component';
import { ErrorViewComponent } from './error-view/error-view.component';
import { EventsViewComponent } from './events-view/events-view.component';
import { SplashImageComponent } from './splash-image/splash-image.component';
import { MatSidenavModule } from '@angular/material/sidenav';

@NgModule({
  declarations: [
    AppComponent,
    TitleBarComponent,
    SiteFooterComponent,
    WelcomeBannerComponent,
    HomeViewComponent,
    ProjectCardComponent,
    ProjectOverviewComponent,
    AboutViewComponent,
    JoinViewComponent,
    ProjectsViewComponent,
    ErrorViewComponent,
    EventsViewComponent,
    SplashImageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    AppRoutingModule,
    MatSidenavModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
