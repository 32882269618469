

<mat-toolbar color="primary">
    <mat-toolbar-row [ngClass]="{'is-phone-portrait': this.deviceSize.getIsPhonePortrait(), 'is-phone-landscape' : this.deviceSize.getIsPhoneLandscape(),
        'is-tablet-portrait' : this.deviceSize.getIsTabletPortrait(), 'is-tablet-landscape' : this.deviceSize.getIsTabletLandscape()}">
        <span class="title"><a routerLink="/home">
            <img src="../../assets/icons8-popcorn-outline-64.png" class="title-icon">popped corn bytes.</a>
        </span>
        <span class="menu-spacer"></span>
        <div class="sidenav-parent">
            <mat-sidenav-container class="sidenav-container" autosize [hasBackdrop]="true">
                <mat-sidenav color="primary" #sidenav class="sidenav-panel" [fixedInViewport]="true" mode="over" position="end">
                    <div class="sidenav-drawer">
                        <div class="drawer-overlay" (click)="sidenav.toggle()">
                        </div>
                        <div class="drawer-content">
                            <div class="drawer-close-symbol" (click)="sidenav.toggle()">
                                <img src="../../assets/icons8-arrow-right-32.png">
                            </div>
                            <div>
                                <a href="/home">
                                    <h2 class="menu-header">
                                        <img src="../../assets/icons8-home-page-32.png"> Home    
                                    </h2>
                                </a>
                                <a href="/projects">
                                    <h2 class="menu-header">
                                        <img src="../../assets/icons8-concept-32.png"> Projects
                                    </h2>
                                </a>
                                <a href="/about">
                                    <h2 class="menu-header">
                                        <img src="../../assets/icons8-info-squared-32.png" alt=""> About
                                    </h2>
                                </a>
                                <a href="/events">
                                    <h2 class="menu-header">
                                        <img src="../../assets/icons8-calendar-32.png"> Events
                                    </h2>
                                </a>
                                <a href="/join">
                                    <h2 class="menu-header">
                                        <img src="../../assets/icons8-add-new-32.png"> Join
                                    </h2>
                                </a>
                            </div>
                        </div>
                    </div>
                </mat-sidenav>
                <mat-sidenav-content class="sidenav-content">
                    <div *ngIf="this.deviceSize.getIsPhonePortrait(); else elseButton">
                        <button mat-mini-fab color="accent" class="menu-icon mat-elevation-z0" aria-label="Site navigation menu" (click)="sidenav.toggle()">
                            <mat-icon>menu</mat-icon>
                        </button>
                    </div>
                    <ng-template #elseButton>
                        <button mat-fab color="accent" class="menu-icon mat-elevation-z0" aria-label="Site navigation menu" type="button" (click)="sidenav.toggle()">
                            <mat-icon>menu</mat-icon>
                        </button>
                    </ng-template>
                </mat-sidenav-content>
            </mat-sidenav-container>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
