<h3>
    About
</h3>
<div class="flex-container card-container">
    <mat-card class="about-card flex-container" [ngClass]="{'is-phone-portrait' : this.deviceSize.getIsPhonePortrait()}">
        <mat-card-header class="flex-container">
            <h4>
                Overview
            </h4>
            <img src="../../assets/icons8-game-controller-96.png" class="flex-item">
        </mat-card-header>
        <mat-card-content>
            <p>
                <b>Popped Corn Games</b> is a community of people building games alongside each other. Like individual kernels coming together to make a delicious snack, 
                Popped Corn offers a variety of projects and activites related to game development. Members can be hobbyists, people aiming to enter the gaming industry, 
                or even experienced veterans. Regardless of a person's skills as an artist, programmer, sound designer, or even marketer, the goal of this group is to 
                provide opporunities to grow your skills and work with like-minded folks!
            </p>
        </mat-card-content>
    </mat-card>
    <mat-card class="about-card flex-container" [ngClass]="{'is-phone-portrait' : this.deviceSize.getIsPhonePortrait()}">
        <mat-card-header class="flex-container">
                <h4>
                    Activities
                </h4>
                <img src="../../assets/icons8-team-96.png" class="flex-item">
        </mat-card-header>
        <mat-card-content>
            <p>
                <b>Byte Projects</b> are group-based, open-source projects inspired by your favourite games and developed by a core team of volunteers. 
                These projects are like game jams, but with less pressure and more time to create a polished product. Using source control (e.g. GitHub), 
                anyone outside the core team can help out with tasks to contribute to the finished product. After publishing, individuals can provide continued
                support by enhancing the game, and even adding DLC experiences.
            </p>
            <p>
                <b>Create Sessions</b> involve working in a virtual computer lab or library environmet (with encouraged side chats!). They provide the helpful 
                social pressure to motivate you to reach your game development goals. Create Sessions take place biweekly, but members can host ad hoc 
                sessions when an extra boost of productivity is needed. Participants can work on personal projects or club-related projects. The choice is yours!
            </p>
            <p>
                As the club grows, prepare to get excited! Additional activities like challenges and game jams will make an appearance.
            </p>
        </mat-card-content>
    </mat-card>
    <mat-card class="about-card flex-container" [ngClass]="{'is-phone-portrait' : this.deviceSize.getIsPhonePortrait()}">
        <mat-card-header class="flex-container">
                <h4>
                    Joining
                </h4>
                <img src="../../assets/icons8-firework-96.png" class="flex-item">
        </mat-card-header>
        <mat-card-content>
            <p>
                Interested in taking part? Why not consider joining the club? All that's needed is an interest in video game development, regardless of your current skills.
                Take a look at how to join and what to expect when you're a <b>Kernel</b> in Popped Corn!
            </p>
            <div class="flex-container">
                <button mat-raised-button color="primary" [routerLink]="'/join'">More Info</button>
            </div>
        </mat-card-content>
    </mat-card>
</div>
