import { Component, OnInit } from '@angular/core';
import { DeviceSizeFinderService } from '../device-size-finder.service';

@Component({
  selector: 'app-events-view',
  templateUrl: './events-view.component.html',
  styleUrls: ['./events-view.component.css']
})
export class EventsViewComponent implements OnInit {

  constructor(public deviceSize: DeviceSizeFinderService) { }

  ngOnInit(): void {
  }

}
