<h3>
    Joining
</h3>
<div class="flex-container card-container">
    <mat-card class="join-card flex-container" [ngClass]="{'is-phone-portrait' : this.deviceSize.getIsPhonePortrait()}">
        <mat-card-header class="flex-container">
            <h4>
                Want to be a Kernel?
            </h4>
            <img src="../../assets/icons8-corn-96.png" class="flex-item">
        </mat-card-header>
        <mat-card-content class="flex-container">
            <p>
                We're looking for people wanting to participate in game development. That's the only criterion! Click the button below 
                to join our Discord server and join the discussion.
            </p>
            <a mat-raised-button color="primary" href="https://discord.gg/BUqf2eqsgq" class="link-button">
                <img src="../../assets/icons8-discord-new-32.png"> Join Discord
            </a>
        </mat-card-content>
    </mat-card>
    <mat-card class="join-card flex-container" [ngClass]="{'is-phone-portrait' : this.deviceSize.getIsPhonePortrait()}">
        <mat-card-header class="flex-container">
                <h4>
                    Want to work on Byte Projects?
                </h4>
                <img src="../../assets/icons8-game-96.png" class="flex-item">
        </mat-card-header>
        <mat-card-content class="flex-container">
            <p>
                Byte Projects are longer-term, not-for-profit projects with dedicated core members. Anyone that's part of the club can participate, but the core team drives
                games to release. These projects focus on a specific brief, and people bring their specialties to the table. It's a great opportunity
                to enhance some of your skills, regardless of what area you participate in. Admin, art, and technical roles are all available. For a better picture,
                 take a look at some of the projects already completed by the club.
            </p>
            <button mat-raised-button color="primary" [routerLink]="'/projects'">Projects</button>
        </mat-card-content>
    </mat-card>
    <mat-card class="join-card flex-container" [ngClass]="{'is-phone-portrait' : this.deviceSize.getIsPhonePortrait()}">
        <mat-card-header class="flex-container">
                <h4>
                    Questions?
                </h4>
                <img src="../../assets/icons8-questions-96.png" class="flex-item">
        </mat-card-header>
        <mat-card-content class="flex-container">
            <p>
                If you have any questions about joining, reach out to Zack Turman at <a href="mailto:poppedcornbytes@gmail.com">poppedcornbytes@gmail.com</a>.
            </p>
        </mat-card-content>
    </mat-card>
</div>
