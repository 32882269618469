import { Component, OnInit } from '@angular/core';
import { DeviceSizeFinderService } from '../device-size-finder.service';

@Component({
  selector: 'app-welcome-banner',
  templateUrl: './welcome-banner.component.html',
  styleUrls: ['./welcome-banner.component.css']
})
export class WelcomeBannerComponent implements OnInit {

  constructor(public deviceSize: DeviceSizeFinderService) { }

  ngOnInit(): void {
  }

}
