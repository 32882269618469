import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HomeViewComponent } from './home-view/home-view.component';
import { AboutViewComponent } from './about-view/about-view.component';
import { JoinViewComponent } from './join-view/join-view.component';
import { ProjectsViewComponent } from './projects-view/projects-view.component';
import { ErrorViewComponent } from './error-view/error-view.component';
import { EventsViewComponent } from './events-view/events-view.component';

const routes: Routes = [
  { path: 'home', component: HomeViewComponent },
  { path: 'about', component: AboutViewComponent },
  { path: 'join', component: JoinViewComponent },
  { path: 'projects', component: ProjectsViewComponent },
  { path: 'events', component: EventsViewComponent },
  { path: 'styles\.css', component: HomeViewComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: ErrorViewComponent }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
