<span class="span-width" [ngClass]="{'is-phone-portrait' : this.deviceSize.getIsPhonePortrait(),
    'is-phone-landscape' : this.deviceSize.getIsPhoneLandscape()}">
    <div class="flex-container">
        <span class="welcome-title">
            welcome to popped corn!
        </span>
        <span class="welcome-subtitle">
            the collaborative game development club
        </span>
        <span class="welcome-joke">
            ps - we don't bite ;)
        </span>
    </div>
</span>
