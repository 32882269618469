<h3>
    Upcoming Events
</h3>
<div class="flex-container card-container">
    <mat-card class="events-card flex-container" [ngClass]="{'is-phone-portrait' : this.deviceSize.getIsPhonePortrait()}">
        <mat-card-header class="flex-container">
            <img src="../../assets/icons8-event-96.png" class="flex-item">
        </mat-card-header>
        <mat-card-content class="flex-container">
            <div class="event-info">
                <h4 class="flex-item">
                    Create Session
                </h4>
                <p class="flex-item">
                    14 March 2023 @ 18:30 GMT
                </p>
            </div>
            <div class="event-info">
                <h4 class="flex-item">
                    Create Session
                </h4>
                <p class="flex-item">
                    28 March 2023 @ 18:30 GMT
                </p>
            </div>
            <div class="event-info">
                <h4 class="flex-item">
                    Create Session
                </h4>
                <p class="flex-item">
                    11 April 2023 @ 18:30 GMT
                </p>
            </div>
            <div class="event-info">
                <h4 class="flex-item">
                    Create Session
                </h4>
                <p class="flex-item">
                    25 April 2023 @ 18:30 GMT
                </p>
            </div>
            <div class="event-info">
                <h4 class="flex-item">
                    Create Session
                </h4>
                <p class="flex-item">
                    9 May 2023 @ 18:30 GMT
                </p>
            </div>
            <div class="event-info">
                <h4 class="flex-item">
                    Create Session
                </h4>
                <p class="flex-item">
                    23 May 2023 @ 18:30 GMT
                </p>
            </div>
            <div class="event-info">
                <h4 class="flex-item">
                    Create Session
                </h4>
                <p class="flex-item">
                    6 June 2023 @ 18:30 GMT
                </p>
            </div>
            <div class="event-info">
                <h4 class="flex-item">
                    Create Session
                </h4>
                <p class="flex-item">
                    20 June 2023 @ 18:30 GMT
                </p>
            </div>
            <div class="flex-container events-link">
                <a mat-raised-button color="primary" href="https://www.meetup.com/bristol-game-creators-club/">
                    <img src="../../assets/icons8-meetup-app-for-hosting-in-person-events-with-similar-interests-24.png"> All Events
                </a>
            </div>
        </mat-card-content>
    </mat-card>
</div>
