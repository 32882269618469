<mat-card background-color="primary" class="project-card"  [ngClass]="{'is-phone-portrait' : this.deviceSize.getIsPhonePortrait()}">
    <mat-card-header>
        <mat-card-title>{{project.title}}</mat-card-title>
        <mat-card-subtitle>{{project.subtitle}}</mat-card-subtitle>
        <iframe frameborder="0" src="https://itch.io/embed/2087989?border_width=0&amp;bg_color=c39af3&amp;link_color=1b784a&amp;border_color=c39af3" width="206" height="165">
            <a href="https://www500.itch.io/brass-necessity-demo">Brass Necessity Demo by www500</a>
        </iframe>
    </mat-card-header>
    <mat-card-content>
        <div>
            <h4>Design Documents</h4>
            <ol>
                <li *ngFor="let link of project.designDocs.links; index as i">
                    <a href="{{link}}">{{project.designDocs.names[i]}}</a>
                </li>
            </ol>
            <h4>Project Links</h4>
            <ol>
                <li *ngFor="let link of project.projectLinks.links; index as i">
                    <a href="{{link}}">{{project.projectLinks.names[i]}}</a>
                </li>
            </ol>
            <h4>Archived Links</h4>
            <ol>
                <li *ngFor="let link of project.archivedLinks.links; index as i">
                    <a href="{{link}}">{{project.archivedLinks.names[i]}}</a>
                </li>
            </ol>
        </div>
   </mat-card-content>
</mat-card>
