<div class="flex-container" [ngClass]="{'is-phone-portrait' : this.deviceSize.getIsPhonePortrait(),
    'is-phone-landscape' : this.deviceSize.getIsPhoneLandscape(), 'is-tablet-portrait' : this.deviceSize.getIsTabletPortrait()}">
    <div class="flex-item">
        <img src="../../assets/icons8-popcorn-machine-96.png">
    </div>
    <div class="flex-item">
        <h2>
            Uh oh! That wasn't a real page...
        </h2>
    </div>
    <div  class="flex-item">
        <button mat-raised-button color="accent" [routerLink]="'/home'">
            Go Home
        </button>
    </div>
</div>
