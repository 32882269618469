import { Component, OnInit } from '@angular/core';
import { DeviceSizeFinderService } from '../device-size-finder.service';

@Component({
  selector: 'app-about-view',
  templateUrl: './about-view.component.html',
  styleUrls: ['./about-view.component.css']
})
export class AboutViewComponent implements OnInit {

  constructor(public deviceSize: DeviceSizeFinderService) { }

  ngOnInit(): void {
  }

}
